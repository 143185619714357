import React, { useState } from 'react';
import PassageDisplay from '../components/PassageDisplay';
import { API_BIBLE_HOSTNAME } from '../config'; // Importing from your config file
import CompoundButton from '../components/CompoundButton';

function AskAWord() {
  const [verses, setVerses] = useState([]);
  const [referencePassage, setReferencePassage] = useState('');
  const [titlePassage, setPassageTitle] = useState('');
  
  // Function to fetch content based on selected version
  const fetchContent = (BibleFilter=1) => {
    // Ensure selectedVersion is not empty
    
    let title = "";
    switch(BibleFilter) {
      case "NT":
        title = "Nuevo Testamento";
        break;
      case "FULL":
        title = "Biblia Completa";break;
      case "GOSPELS":
        title = "Evangelios";break;
      default:
        title="";
    }   
      

    fetch(`${API_BIBLE_HOSTNAME}/v1/sections/random/common/abbr/BH?bibleFilter=${BibleFilter}`, {
      method: "GET",
      headers: {
        "accept": "application/json"
      }
    })
    .then(response => response.json())
    .then(data => {
      const _verses= data.result.verses
      setVerses(_verses);
      setPassageTitle(title);
      setReferencePassage(data.result.passage.book + " " + data.result.passage.chapter + "," + _verses[0].verse + "-" + _verses[_verses.length-1].verse);
    })
    .catch(error => console.error("Error fetching content:", error));
  };

  const [selected, setSelected] = useState('');

  const executeSelection = () => {
    console.log('Executing:', selected);
    // Implement the action to be executed based on the selection
  };

  const optionsAskAWordButton = {
    'GOSPELS': 'Evangelios',
    'NT': 'Nuevo Testamento',
    'FULL': 'Biblia Completa'
  };


  return (
    <div>
      <CompoundButton options={optionsAskAWordButton} actionToExecute={fetchContent} labelButtonRun={"Pedir"} defaultSelected={"FULL"}  />

      <div style={{ clear:"both", display:"block"}}>
        <PassageDisplay verses={verses} reference={referencePassage} title={(titlePassage) ? titlePassage : ""} />
      </div>
    </div>
  );
}

export default AskAWord;
