import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPlay, FaPause, FaSpinner, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';

function AudioPlayer({ src, shouldStop=false }) {
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  const loadAudio = () => {
    if (audio) {
      // Si ya existe un objeto audio, detener cualquier reproducción actual.
      audio.pause();
      audio.currentTime = 0;
    }

    // Crear un nuevo objeto Audio y asignarlo.
    const newAudio = new Audio(src);
    setAudio(newAudio);
    setIsPlaying(false);
    setIsLoading(true);
    // Agregar manejadores de eventos al nuevo objeto audio.
    newAudio.onloadeddata = () => {
        setIsLoading(false);
        if(!shouldStop) newAudio.play();
      };
    newAudio.onplay = () => setIsPlaying(true);
    newAudio.onpause = () => {
        setIsPlaying(false);
      };
    newAudio.onerror = () => {
        setIsLoading(false);
      };
  };

  useEffect(() => {    
    // Inicializar o actualizar el objeto Audio cuando cambie src.
    // Se crea la función dentro del efecto para manejar correctamente las dependencias.

    loadAudio();
    // Limpieza: Detener el audio cuando el componente se desmonte o antes de la próxima actualización de src.
    return () => {
      if (audio) {
        audio.pause();
      }
    };
  }, [src,shouldStop]); // Este efecto se ejecuta solo cuando src cambia.

  const togglePlayPause = () => {
    if (!audio) return;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play().catch(error => {
        setIsLoading(false);
        console.error('Error al reproducir el audio:', error);
      });
    }
  };

// Utility function to detect mobile devices
function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  return (
      
    <div style={{display:'inline'}}>
      {isMobile ? (
            // For mobile devices, render a simple audio tag with native controls.
            <audio src={src} controls>
              Your browser does not support the audio element.
            </audio>
          ) : (
        <>
          <button 
            onClick={togglePlayPause} 
            disabled={isLoading || !src} 
            className='audio-button'
            
          >
            {isPlaying ? <FaPause /> : (isLoading ? <FaSpinner className='spinner' /> : <FaPlay />)}
          </button>
          
        </>
      ) }
    </div>
    )}
    

export default AudioPlayer;
