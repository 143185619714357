import React, { useState } from 'react';
import WhatsAppShareButton from './WhatsappShareButton';
import { PUBLIC_HOSTNAME,API_BIBLE_HOSTNAME } from '../config';
import { Link } from 'react-router-dom';
import AudioPlayer from './AudioPlayer';
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';

// Utility function to group verses by chapter
const groupVersesByChapter = (verses) => {
  const groupedVerses = [];
  let currentChapter = null;
  let currentGroup = [];

  verses.forEach(verse => {
    if (verse.chapter !== currentChapter) {
      if (currentGroup.length > 0) {
        groupedVerses.push(currentGroup);
        currentGroup = [];
      }
      currentChapter = verse.chapter;
    }
    currentGroup.push(verse);
  });

  if (currentGroup.length > 0) {
    groupedVerses.push(currentGroup);
  }

  return groupedVerses;
};


const PassageDisplay = ({ verses, reference, title }) => {
  const [showControls, setShowControls] = useState(false);
  const [shouldStopAudio, setShouldStopAudio] = useState(true);
  const groupedVerses = groupVersesByChapter(verses);
  

  const toggleControls = () => {
    setShouldStopAudio(!shouldStopAudio);
    setShowControls(true);
  };


  return (
    <div className='passage-display'>
      <h1>{title}</h1>
      {(reference) ? <><small>Cita: {reference} &nbsp;</small>
      
        <WhatsAppShareButton 
        message={"Cita: *"+ reference + "*\n\n" + verses.map(verse => verse.text).join('\n')}
        url={reference ? PUBLIC_HOSTNAME +"/passage?passage="+ encodeURIComponent(reference) : "" } 
      />
      <Link to="#" onClick={toggleControls} className='audio-button'>{showControls ? <FaVolumeUp /> : <FaVolumeMute />}</Link>
      {showControls && (
      <AudioPlayer shouldStop={shouldStopAudio} src={API_BIBLE_HOSTNAME+ "/v1/test/text-to-speech?text=" + encodeURIComponent(verses.map(verse => verse.text).join(' '))} ></AudioPlayer>
      )}
      </>
       : ''}
      

      {groupedVerses.map((chapterVerses, chapterIndex) => (
        <React.Fragment key={chapterIndex}>
          {groupedVerses.length>1 ? <h2>Capitulo: {chapterVerses[0].chapter}</h2> : ""}
          {chapterVerses.map((item, verseIndex) => (
              <React.Fragment key={verseIndex}>
                {(item.title) ? <h3>{item.title}</h3> : ''}
                <p>
                {(item.verse) ? <sup>{item.verse}</sup>  : ''}
                {(item.number) ? <sup>{item.number}</sup>  : ''}&nbsp;
                {item.text}
                {verseIndex < verses.length - 1 && <br />}
                </p>
                
              </React.Fragment>
            ))}
        </React.Fragment>
      ))}
      
    </div>
  );
};



export default PassageDisplay



