import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

function WhatsAppShareButton({ message, url }) {
  // Encode your message and the URL to make them URL-safe
  const encodedMessage = encodeURIComponent(message + ((url) ? "\n" + url : ""));

  // Create the WhatsApp share URL
  const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;

  return (
    <a href={whatsappUrl} target="_blank" className='audio-button' rel="noopener noreferrer">
      <FaWhatsapp />
    </a>
  );
}

export default WhatsAppShareButton;
