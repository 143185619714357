import React, { useState, useEffect } from 'react';
import PassageDisplay from '../components/PassageDisplay';
import { fetchBooks, fetchChapterAbb, fetchChapters } from '../services/apiService';


function bookList(books,handleBookSelect) {

  return <div>
        {books.map(book => (
          <React.Fragment key={book.abbreviation}>
          {book.id===1 && <h2>Antiguo Testamento</h2> }
          {book.id===101 && <h2>Nuevo Testamento</h2> }
          <div className="buttonContainer" key={book.abbreviation}>
            <button onClick={() => handleBookSelect(book)}>
              {book.bookname}
            </button>
          </div>
          </React.Fragment>
        ))}
      </div>
}

function BookSelector() {
  const [selectedVersion, setSelectedVersion] = useState('');
  const [books, setBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState('');
  const [bookChapters, setChapters] = useState({"chapters":[]});
  const [selectedChapter, setSelectedChapter] = useState('');
 
  const [verses, setVerses] = useState([]);
  const [referencePassage, setReferencePassage] = useState('');
  const [titlePassage, setPassageTitle] = useState('');
  const [isBooksExpanded, setIsBooksExpanded] = useState(true); 
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded); // Toggle sidebar visibility
  };
  
  useEffect(() => {
    setSelectedVersion("BH");
    fetchBooks()
      .then(data => setBooks(data.result || []))
      .catch(error => console.error("Error fetching books:", error));
  }, []);

  // Other useEffects remain the same

  const toggleBooksVisibility = () => {
    setIsBooksExpanded(!isBooksExpanded);
  };

  useEffect(() => {
    setSelectedVersion("BH");
    fetchBooks()
    //.then(response => response.json())
    .then(data => setBooks(data.result || []))
    .catch(error => console.error("Error fetching books:", error));
  }, []);

  useEffect(() => {
    if (selectedBook) {
      console.log(selectedBook);
      let abb = selectedBook.abbreviation.split(",")[0];
      fetchChapters(selectedVersion,abb)
    //    .then(response => response.json())
        .then(data => setChapters(data.result || []))
        .catch(error => console.error("Error fetching chapters:", error));
    }
  }, [selectedBook, selectedVersion]);

  useEffect(() => {
    if (selectedChapter) {
        let abb = selectedBook.abbreviation.split(",")[0];
        fetchChapterAbb('BH',abb,selectedChapter)
          //.then(response => response.json())
          .then(data => {
            setVerses(data.result.verses);
            setPassageTitle("Libro: " + data.result.book );
            setReferencePassage(data.result.book + " " + data.result.chapter);
          })
          .catch(error => console.error("Error fetching chapters:", error));
      }
  }, [selectedBook, selectedVersion,selectedChapter]);


  const handleBookSelect = (book) => {
    setSelectedBook(book);
    setSelectedChapter(''); // Reset selected chapter when book changes
    toggleBooksVisibility();
  };

  const handleChapterSelect = (chapterNumber) => {
    // Here you would fetch the content for the selected chapter
    // For simplicity, I'm just setting the chapter number as content
    setSelectedChapter(chapterNumber);
    handleToggleSidebar();
  };


  return (
    
    <div className={`container ${!isSidebarExpanded ? 'sidebar-collapsed' : ''}`}>
      <button onClick={handleToggleSidebar} className="toggle-sidebar">
          {isSidebarExpanded ? '<<' : '>>'}
        </button>
      <div className="sidebar">
        {isSidebarExpanded && (
          
          <div>
            {selectedBook ? (
            <div>
              <button onClick={toggleBooksVisibility}>
                {isBooksExpanded ? 'Ocultar lista' : `Ver Libros`}
              </button>
              {isBooksExpanded && bookList(books,handleBookSelect)}
            </div>
            ) : (bookList(books,handleBookSelect))}

            {selectedBook && !isBooksExpanded ? (
            <div>
                <h2>{selectedBook.bookname}</h2>
                {bookChapters.chapters.sort((a, b) => a - b).map((chapter, index) => (
                  <div className="buttonContainer" key={`chapter_${index}`}>
                    <button onClick={() => handleChapterSelect(chapter)}>
                      Capitulo {chapter}
                    </button>
                  </div>
                ))}
              </div>
            ): null}

          </div>
        )}
      </div>
      <div className="content">
        {selectedChapter  ?(
          <PassageDisplay verses={verses} reference={referencePassage} title={titlePassage ? titlePassage : ""} />
        ): ""}
      </div>
    </div>
  );
}


export default BookSelector;
