import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation
} from 'react-router-dom';
import AskAWord from './sections/Askaword';
import BookSelector from './sections/BookSelector';
import PassageSearch from './sections/PassageSearch';

// Navigation component
const Navigation = () => {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  
  return (
    <header className="fixed">
      <nav className="menu">
        <ul>
          <li>
            <Link to="/random" className={currentPath === "random" ? "selected" : ""}>Al Azar</Link>
          </li>
          <li>
            <Link to="/navigate" className={currentPath === "navigate" ? "selected" : ""}>Navegar Biblia</Link>
          </li>
          <li>
            <Link to="/passage" className={currentPath === "passage" ? "selected" : ""}>Cita</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

// App component
function App() {
  return (
    <Router>
      <Navigation /> 
      <main className='mt-11 pt-safe-top'>
        <Routes>
          <Route path="/random" element={<AskAWord />} />
          <Route path="" element={<BookSelector />} />
          <Route path="/navigate" element={<BookSelector />} />
          <Route path="/passage" element={<PassageSearch />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;