import React, { useState, useRef, useEffect } from 'react';

function CompoundButton({options,actionToExecute,labelButtonRun="Execute",defaultSelected=Object.keys(options)[0]}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelected);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState(options[defaultSelected]);
  const toggleRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (toggleRef.current && !toggleRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [toggleRef]);

  const handleOptionSelect = (key, label) => {
    setSelectedOption(key);
    setSelectedOptionLabel(label);
    setIsOpen(false);
    // You might want to call the actionToExecute function here or wait for the user to click the execute button
  };

  const executeAction = () => {
    console.log('Executing action for:', selectedOption);
    // Implement the action to be executed based on the selection
    actionToExecute(selectedOption);
  };
  
  console.log(Object.entries(options));

  return (
    <div className="compound-button" ref={toggleRef}>
      <button className="selector" onClick={() => setIsOpen(!isOpen)}>
        {selectedOptionLabel}
      </button>
      <button className="executor" onClick={executeAction}>
        {labelButtonRun}
      </button>
      {isOpen && (
        <div className="dropdown">
          {Object.entries(options).map(([key, label]) => (
            <div className="dropdown-item" key={key} onClick={() => handleOptionSelect(key, label)}>
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CompoundButton;