import React, { useState, useEffect } from 'react';
import PassageDisplay from '../components/PassageDisplay'; // Adjust the path as necessary
import { API_BIBLE_HOSTNAME } from '../config';

const PassageSearch = () => {
    const [input, setInput] = useState('');
    const [title, setTitle] = useState('');
    const [passages, setPassages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const fetchPassage = async (passage) => {
        setIsLoading(true);
        try {
            if(!passage) {
                setPassages([]);
                setTitle("")
                return false;}
            const response = await fetch(`${API_BIBLE_HOSTNAME}/v1/passages/BH/${passage}`);
            if (!response.ok) {
                setPassages([]);
                setTitle("No encontrado...")
                return true;
            }
            const data = await response.json();
            setPassages(data.result.verses); // Adjust according to your API response structure
            setTitle("Libro: " + data.result.verses[0]["book"])
        } catch (error) {
            setPassages([]);
            setTitle("No encontrado...")
            console.error("Error fetching passage:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const suggestions = ['Gn 1,1-4', '1 Juan 1:1-2', '1 Cor 13','Marcos 14,5-10','Mt 1:16;2,1;3,12']; // Example suggestions

    // Filter suggestions based on input
    const filteredSuggestions = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(input.toLowerCase())
    );

    useEffect(() => {
        // Parse the query string for the 'passage' parameter
        const queryParams = new URLSearchParams(window.location.search);
        const passage = queryParams.get('passage');
        if (passage) {
            setInput(passage);
            fetchPassage(passage);
        }
    }, []);

    // Inside your component
    const handleSubmit = (e, currentInput=input) => {
        e.preventDefault(); // Prevent default form submission behavior
        fetchPassage(currentInput);
    };

  return (
    <div>
        <form onSubmit={handleSubmit}>
            Cita: <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyUp={handleSubmit}
                onBlur={handleSubmit} // Optionally trigger on blur
                placeholder="Ingresar cita de la biblia..."
                className='selector'
            />

        
            {(input === "") && ( 
                <div>
                Ejemplos:
                {filteredSuggestions.map((suggestion) => (
                    <div
                    key={suggestion}
                    onClick={(e) => {setInput(suggestion);handleSubmit(e,suggestion)}}
                    style={{ cursor: 'pointer', paddingLeft: '10px' }}
                    >
                    {suggestion}
                    </div>
                ))}
                </div>
            )}
            

            
        </form>
  
  
        {isLoading ? (
        <p>Cargando...</p>
        ) : (
        <PassageDisplay title={title} verses={passages} titlePassage="" reference={input} />
        )}
        
    </div>
  );
};

export default PassageSearch;
/* 




<button type="submit">Search</button>*/