// src/services/apiService.js
import { API_BIBLE_HOSTNAME } from '../config';

// Function to fetch books based on the selected version
export const fetchBooks = async () => {
    try {
      const response = await fetch(`${API_BIBLE_HOSTNAME}/v1/books/lang/SPA`);
      if (!response.ok) {
        throw new Error('Failed to fetch books');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error in fetchBooks:", error);
      throw error; // Re-throw to handle it in the component
    }
  };

// Function to fetch books based on the selected version
export const fetchChapters = async (selectedVersion,bookAbbreviation) => {
  try {
    const response = await fetch(`${API_BIBLE_HOSTNAME}/v1/chapters/${selectedVersion}/${bookAbbreviation}`);
    if (!response.ok) {
      throw new Error('Failed to fetch books');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in fetchChapters:", error);
    throw error; // Re-throw to handle it in the component
  }
};

// Function to fetch chapters of a book
export const fetchChapterAbb = async (selectedVersion, bookAbbreviation, selectedChapter) => {
  try {
    const response = await fetch(`${API_BIBLE_HOSTNAME}/v1/chapters/${selectedVersion}/${bookAbbreviation}/${selectedChapter}`);
    if (!response.ok) {
      throw new Error('Failed to fetch chapters');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in fetchChapter:", error);
    throw error;
  }
};

// Add more functions as needed for other endpoints
